import React from 'react';
import styled from 'react-emotion';
import { SMALL_DEVICE_BREAKPOINT } from '../../styles/responsive';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../routing/components/LocaleWrapper/LocaleWrapper';
import { LOCALES } from '../../locales';

const Banner = styled('div')`
  min-height: 120px;
  width: 78%;
  align-self: center;
  background-color: #ff3131;
  background-size: cover;
  margin-bottom: 40px;
`;

const CampaignLabel = styled('h2')`
  padding: auto;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 61px;
  color: #fff;
  text-align: center;
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 26px;
    line-height: 41px;
  }
`;

const CouponLabel = styled('h3')`
  padding: auto;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 61px;
  color: #fff;
  text-align: center;
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 22px;
    line-height: 41px;
  }
`;

/**
 * 期間限定で行われるキャンペーンのバナー
 *
 * NOTE:
 * 期間限定での実装なので、このコンポーネントが1度も使用されない機会があるが、コンポーネント自体を削除する際は確認すること。
 *
 */
const CampaignBanner = () => {
  const { t } = useTranslation();

  const locale = useLocale();

  const couponCode = 'RELEASECAMPAIGN'; // 適用したいクーポンコード（複数個は配慮していない）
  const japanFinishDateString = '2024-11-24T00:00:00+09:00'; // キャンペーン終了日時（日本時間）。+09:00は必須。

  /**
   *
   * @param {string} finishDate キャンペーン終了日時（日本時間）
   * @returns 残り期間ごとのキャンペーンセールのメッセージを返す
   */
  const getLimitDatMessage = finishDate => {
    const nowDate = new Date(); // 現在時刻
    const japanFinishDate = new Date(finishDate);
    const diffMilliseconds = japanFinishDate - nowDate; // 差分の計算（ミリ秒単位）
    const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24)); // ミリ秒から日数を計算

    if (diffDays > 3) return '';

    if (diffDays > 1) {
      if (locale === LOCALES.japanese.code) {
        return `残り${diffDays}日。`;
      } else {
        return `Ends in ${diffDays} days.`;
      }
    } else if (diffDays === 1) {
      if (locale === LOCALES.japanese.code) {
        return '明日最終日！！';
      } else {
        return 'Ends Tomorrow!!';
      }
    } else if (diffDays === 0) {
      if (locale === LOCALES.japanese.code) {
        return '本日最終日！！';
      } else {
        return 'Ends Today!!';
      }
    } else {
      // 消し忘れ用
      if (locale === LOCALES.japanese.code) {
        return '終了しました。';
      } else {
        return 'Ends.';
      }
    }
  };

  return (
    <Banner>
      <CampaignLabel>
        {t('general.banner.campaignText', {
          defaultValue: '',
          limitMessage: getLimitDatMessage(japanFinishDateString),
        })}
      </CampaignLabel>
      <CouponLabel>
        {t('general.banner.couponText', { defaultValue: '', couponCode: couponCode })}
      </CouponLabel>
    </Banner>
  );
};

export default CampaignBanner;
