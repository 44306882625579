// @flow
import React, { useState } from 'react';
import styled from 'react-emotion';
import { flatten } from 'lodash';
import IntervalNavigation from './IntervalNavigation';
import PricingPlan from './PricingPlan';
import {
  BILLING_CYCLES,
  getAvailableBillingCycles,
  SUBSCRIPTIONS,
} from '../../../../../../user/subscriptions/data';
import { useSubscriptionsContext } from '../../../../../../user/subscriptions/components/SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  isCreatorSubscription,
  isEnterpriseSubscription,
} from '../../../../../../utils/subscriptions';
import { MEDIA_BREAKPOINTS, SMALL_DESKTOP_BREAKPOINT } from '../../../../../../styles/responsive';
import {
  usePricingPage,
  usePricingPagePlans,
} from '../../../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import { getPricingPagePromotionalDefaultInterval } from '../../../../../../cms/pricingPage';
import { getPricingPagePlanAvailableIntervals } from '../../../../../../cms/pricingPagePlan';
import PaymentTabNavigation from '../../../../../../components/Link/components/PaymentTabNavigation';

const Conteiner = styled('div')`
  .radio-button-group {
    margin-top: 30px;
    padding-left: 0;
    justify-content: center;
  }
`;

const PricingPlansContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px auto auto auto;
  width: 80%;

  & button {
    margin: 0px 5px;
  }

  ${MEDIA_BREAKPOINTS.max1260} {
    width: 100%;
  }

  ${SMALL_DESKTOP_BREAKPOINT} {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

const PricingPlans = () => {
  const pricingPageCopy = usePricingPage();
  const promotionalDefaultInterval = getPricingPagePromotionalDefaultInterval(pricingPageCopy);
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const pricingPlans = usePricingPagePlans();
  const availableIntervals = pricingPlans.map(plan => getPricingPagePlanAvailableIntervals(plan));
  const availableIntervalsSet = new Set(flatten(availableIntervals));
  const uniqueAvailableIntervals = [...availableIntervalsSet];
  const availableBillingCycles = getAvailableBillingCycles(uniqueAvailableIntervals);
  const orderedIntervals = Object.keys(availableBillingCycles).map(
    billingCycle => BILLING_CYCLES[billingCycle].cycle
  );

  const [interval, setInterval] = useState(defaultInterval);
  const [selectOption, setSelectOption] = useState('oneTimePayment');

  const { isFreeCustomer } = useSubscriptionsContext();
  const previousIntervalAvailable =
    orderedIntervals.findIndex(orderedInterval => {
      return interval === orderedInterval;
    }) > 0;
  const nextIntervalAvailable =
    orderedIntervals.findIndex(orderedInterval => {
      return interval === orderedInterval;
    }) <
    orderedIntervals.length - 1;

  const handleIntervalChange = (newInterval: string) => {
    setInterval(newInterval);
  };

  const handleChangeNextPreviousInterval = (currentInterval: string, moveNext: boolean) => {
    const currentIndex = orderedIntervals.findIndex(orderedInterval => {
      return currentInterval === orderedInterval;
    });
    let newInterval = orderedIntervals[currentIndex];

    if (moveNext && nextIntervalAvailable) {
      newInterval = orderedIntervals[currentIndex + 1];
    }

    if (!moveNext && previousIntervalAvailable) {
      newInterval = orderedIntervals[currentIndex - 1];
    }
    setInterval(newInterval);
  };

  const handleOptionChange = (paymentMethod: string) => {
    setSelectOption(paymentMethod);
  };

  const filteredPricingPlans = (() => {
    const plans = [
      SUBSCRIPTIONS.creatorPro.key,
      SUBSCRIPTIONS.business.key,
      SUBSCRIPTIONS.enterprise.key,
    ];

    if (isFreeCustomer) {
      const filteredPlans = plans.filter(plan => {
        return !isCreatorSubscription(plan);
      });
      return filteredPlans;
    }
    return plans;
  })();

  return (
    <Conteiner>
      <IntervalNavigation
        handleIntervalChange={handleIntervalChange}
        handleChangeNextPreviousInterval={handleChangeNextPreviousInterval}
        selectedInterval={interval}
      />

      <PaymentTabNavigation handleOptionChange={handleOptionChange} />

      <PricingPlansContainer>
        {filteredPricingPlans.map(filteredPlan => {
          return (
            <PricingPlan
              key={filteredPlan}
              planCode={filteredPlan}
              selectedInterval={interval}
              selectOption={selectOption}
              handleIntervalChange={handleChangeNextPreviousInterval}
              isNextAvailable={nextIntervalAvailable}
              isPreviousAvailable={previousIntervalAvailable}
              hasExternalLink={isEnterpriseSubscription(filteredPlan)}
            />
          );
        })}
      </PricingPlansContainer>
    </Conteiner>
  );
};

export default PricingPlans;
