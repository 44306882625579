// @flow
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import parse from 'html-react-parser';
import { transparentize } from 'polished';
import styled from 'react-emotion';
import { css, cx } from 'emotion';
import { wideModalCss } from '../../../../modals/components/Modal/Modal';
import ClassicModalLayout, {
  ClassicModalContentLayout,
} from '../../../../modals/components/ClassicModalLayout/ClassicModalLayout';
import {
  useMiscCopy,
  usePricingPlan,
  usePricingPlans,
  useSubscriptionsPage,
} from '../../../../copy/components/CopyContextWrapper/CopyContextWrapper';
import {
  getAddSubscriptionModalTitle,
  getChangeSubscriptionModalTitle,
  getDowngradeSubscriptionModalTitle,
  getUpgradeSubscriptionModalTitle,
  getSubscriptionsPagePromotionalLabelNewPlan,
  getSubscriptionsPagePromotionalLabelUpgradePlan,
  getSubscriptionsPagePromotionalLabelIntervalChange,
  isPromotionAvailable,
  getSubscriptionsPagePromotionalDefaultInterval,
} from '../../../../cms/subscriptionsPage';
import {
  creatorProBgColor,
  lightColor,
  darkColor,
  enterpriseBgColor,
} from '../../../../styles/config/colors';
import {
  hideOnLargeDeviceCss,
  SMALL_DEVICE_BREAKPOINT,
  LANDSCAPE_DEVICE,
  MOBILE_DEVICE_BREAKPOINT,
} from '../../../../styles/responsive';
import Button, { buttonResetCss } from '../../../../components/Button/Button';
import {
  bodyTextCss,
  hugeTextCss,
  monoFontCss,
  monoNavCss,
  smallMediumPlusTextCss,
} from '../../../../styles/typography/typography';
import {
  getPricingPlan,
  getPricingPlanName,
  getPricingPlanMonthlyBillingSubtext,
  getPricingPlanExclusions,
  getPricingPlanAvailableIntervals,
} from '../../../../cms/pricingPlan';
import type { PricingPlanPricingDetails } from '../../../../cms/pricingPlan';
import { getMiscCopyAdd } from '../../../../cms/miscCopy';
import {
  SUBSCRIPTIONS,
  BILLING_CYCLES,
  CURRENCY_CODES,
  INTERVAL_MONTHLY_DURATION,
  getAvailableBillingCycles,
} from '../../data';
import { useSubscriptionsContext } from '../SubscriptionsContextWrapper/SubscriptionsContextWrapper';
import {
  getIntervalCycle,
  getNextAvailableInterval,
  getIntervalLabel,
  getSeatInterval,
  getSubscriptionPlanKey,
} from '../../../../api/firebase/user/subscriptions';
import type { UserSubscriptionMdl } from '../../../../api/firebase/user/subscriptions';
import { ACTIONS } from '../ChangeSubscriptionModal/ChangeSubscriptionModal';
import { useLocale } from '../../../../routing/components/LocaleWrapper/LocaleWrapper';
import {
  isCreatorSubscription,
  isCreatorProSubscription,
  isBusinessSubscription,
  isEnterpriseSubscription,
  isEnterpriseBasicSubscription,
} from '../../../../utils/subscriptions';

const centerMobileModalHeader = css`
  ${SMALL_DEVICE_BREAKPOINT} {
    & h2 {
      padding-top: 10px;
    }
  }
`;

const creatorProPlanCss = css`
  background-color: ${creatorProBgColor};
`;

const enterprisePlanCss = css`
  background-color: ${enterpriseBgColor};
`;

const Nav = styled('nav')`
  margin: 0px 0px 30px 0px;

  ul {
    line-height: 1;
    display: flex;
    justify-content: flex-start;
  }
  li {
    text-align: left;
    margin-right: 20px;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    border-top: 1px solid ${transparentize(0.95, lightColor)};
    border-bottom: 1px solid ${transparentize(0.95, lightColor)};
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 5px;

    & ul {
      justify-content: center;

      & li {
        flex: 1;
        margin-right: 0px;
      }
    }
  }
`;

const NavButton = styled('button')`
  ${buttonResetCss};
  ${smallMediumPlusTextCss};
  display: block;
  width: 100%;
  font-size: ${props => (props.locale === 'ja' ? '24px' : '30px')};
  color: ${props => (props.selected ? darkColor : transparentize(0.6, darkColor))};
  font-weight: 300;
  text-align: left;

  &:focus,
  &:hover {
    color: ${darkColor};
    outline: none;

    ${SMALL_DEVICE_BREAKPOINT} {
      text-decoration: none;
    }
  }
  &:active {
    color: ${transparentize(0.2, darkColor)};
  }
  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 16px;
    letter-spacing: 0.38px;
    padding: 6px 5px;
    text-decoration: none;
    text-align: center;
  }
  ${MOBILE_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const BodyContent = styled('section')`
  display: flex;
`;

const InfoSection = styled('section')`
  flex: 1;
  margin-right: 50px;

  ${SMALL_DEVICE_BREAKPOINT} {
    margin-right: 0px;
  }
`;

const InfoDescription = styled('div')`
  ${bodyTextCss};

  ul {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;

    li {
      margin-top: 10px;
      padding-left: 20px;
      position: relative;
      &::before {
        content: '•';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const FeaturesList = styled('div')`
  ${bodyTextCss};

  ul {
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;
    list-style: none;

    li {
      margin-top: 10px;
      padding-left: 20px;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
      &:first-of-type {
        margin-top: 0px;
      }
    }
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    font-size: 12px;
  }
`;

const InclusionsList = styled(FeaturesList)`
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 20px;
  }

  h3 {
    font-weight: 700;
  }

  ul {
    padding: 12px;
  }

  li {
    list-style-position: outside;
    padding-bottom: 5px;
    font-size: 13px;
  }
`;

const ExclusionsList = styled(FeaturesList)`
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 10px;
  }

  ul {
    margin-top: 0px;
    opacity: 0.5;

    li {
      &:first-of-type {
        margin-top: 10px;
      }
      &::before {
        font-weight: bold;
        content: 'x';
        top: -1px;
      }
    }
  }
`;

const PricingSection = styled('section')`
  width: 100%;
  max-width: 170px;
  ${SMALL_DEVICE_BREAKPOINT} {
    display: none;
  }
`;

const pricingHeading = css`
  ${hugeTextCss};
`;

const pricingSubheading = css`
  ${monoNavCss};
`;

const Price = styled('div')`
  background-color: ${transparentize(0.95, lightColor)};
  border-radius: 2px;
  padding: 22px 5px 20px 5px;
  text-align: center;
  margin-bottom: 10px;
  max-height: 120px;
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Mono', sans-serif;

  h3 {
    ${pricingHeading};
    font-size: ${props => (props.currencyCode === 'JPY' ? '25px' : '30px')};
  }
  p {
    ${pricingSubheading};
    opacity: 0.6;
    text-transform: uppercase;
    margin-top: 5px;

    &:last-of-type {
      margin-top: 2px;
    }
  }

  & sub {
    font-size: 20px;
  }
`;

const MobilePricingContainer = styled('section')`
  ${hideOnLargeDeviceCss};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
`;

const MobilePricing = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: ${transparentize(0.95, lightColor)};
  border-radius: 2px;
  width: 100%;
  margin-top: 20px;
  h3 {
    ${pricingHeading};
    font-size: 30px;
    line-height: 45px;
    margin-left: 10px;
    margin-right: 1px;
    vertical-align: bottom;
    font-family: 'Roboto Mono', sans-serif;
  }

  & sub {
    font-size: 14px;
    position: relative;
    top: 6px;
  }

  & div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-end;
    margin-bottom: 7px;
    margin-left: 15px;
    opacity: 0.6;
  }

  p {
    ${monoFontCss};
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 14px;
    padding-bottom: 4px;
  }
`;

const multiParagraphSubtitleMobile = css`
  ${MOBILE_DEVICE_BREAKPOINT} {
    align-self: center !important;
    margin-bottom: 0px !important;
    margin-top: 3px !important;
  }
`;

const hiddenButtonClass = css`
  visibility: hidden;
  pointer-events: none;
`;

const addButtonCss = css`
  background-color: #4d4d4d;
`;

const ActionButtonWrapper = styled('div')`
  margin-top: 45px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 30px;
  }
`;

const BillingCyclesWrapper = styled('div')`
  margin-top: 0px;
  width: 170px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const BillingCycleButton = styled(Button)`
  margin-top: 5px;
  background-color: ${transparentize(1, lightColor)};
  color: ${lightColor};
  border: 1px solid ${transparentize(0.9, lightColor)};
  min-width: 170px;
  ${SMALL_DEVICE_BREAKPOINT} {
    margin-top: 5px;
    width: 49%;
    min-width: 120px;
    display: flex;
    justify-content: center;
    &:nth-child(odd) {
      order: 1;
    }
    &:nth-child(even) {
      order: 2;
    }
    ${LANDSCAPE_DEVICE} {
      min-width: 150px;
    }
  }
`;

const activeBillingCycleButton = css`
  background-color: ${lightColor};
  color: ${darkColor};
  ${SMALL_DEVICE_BREAKPOINT} {
    min-width: 120px;
  }
`;

const disabledBillingCycleButton = css`
  background-color: ${transparentize(1, lightColor)};
  border: 1px solid ${transparentize(0.95, lightColor)};

  & span {
    color: ${transparentize(0.8, lightColor)};
  }
`;

const landscapeSubscriptionModal = css`
  ${wideModalCss};

  svg g {
    stroke: #fff;
  }

  ${SMALL_DEVICE_BREAKPOINT} {
    ${LANDSCAPE_DEVICE} {
      width: 100%;
      max-width: none;
    }
  }
`;

const PromotionLabel = styled('div')`
  text-align: center;
  margin-top: 20px;
  border-radius: 2px;
  ${creatorProPlanCss}
  background-size: cover;
  background-position: center;
  min-height: 100px;

  ${SMALL_DEVICE_BREAKPOINT} {
    min-height: unset;
    width: 100%;
  }

  & div {
    width: 100%;
    height: 100%;
    background-color: ${transparentize(0.5, darkColor)};
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h6 {
    height: 30%;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.25px;
    padding: 10px 0px 2px 0px;
    color: ${lightColor};
  }

  & p {
    height: 70%;
    font-size: 12px;
    padding: 0px 7px 10px 7px;
    line-height: 20px;
  }
`;

const japaneseLocalePromotionLabel = css`
  & h6:first-of-type {
    padding: 10px 20px 0px 20px;
  }

  & h6:last-of-type {
    padding: 0px 20px 10px 20px;
  }

  & p {
    padding: 0px 10px 10px 10px;
  }
`;

const hiddenBillingCycles = css`
  display: none;
`;

type Props = {
  disableAction?: boolean,
  busy: boolean,
  selectedPlan: string,
  selectPlan: (planKey: string) => void,
  onClose: () => void,
  actionText: string,
  action: () => void,
  changingSubscription?: UserSubscriptionMdl | false,
  subscriptionInterval?: string | null,
  subscriptionPlanKey?: string,
  subscriptionModalType: string,
};

const getPricingPlanPricingDetails = (plan: PricingPlanMdl): PricingPlanPricingDetails => {
  return {
    monthly: {
      price: get(plan, 'data.monthly_price.text', ''),
      price_subtitle: get(plan, 'data.monthly_price_subtitle.html', ''),
    },
    quarterly: {
      price: get(plan, 'data.quarterly_price.text', ''),
      price_subtitle: get(plan, 'data.quarterly_price_subtitle.html', ''),
    },
    biannual: {
      price: get(plan, 'data.biannually_price.text', ''),
      price_subtitle: get(plan, 'data.biannually_price_subtitle.html', ''),
    },
    annual: {
      price: get(plan, 'data.annually_price.text', ''),
      price_subtitle: get(plan, 'data.annually_price_subtitle.html', ''),
    },
  };
};

const getPricingPlanIntervalPrice = (
  pricingPlanPricingDetails: PricingPlanPricingDetails,
  interval: string
): string => {
  return get(pricingPlanPricingDetails, `[${interval}].price`, '') || '';
};

const getPricingPlanIntervalSubtitle = (
  pricingPlanPricingDetails: PricingPlanPricingDetails,
  interval: string
): string => {
  return get(pricingPlanPricingDetails, `[${interval}].price_subtitle`, '') || '';
};

const shouldButtonBeDisabled = (
  selectedPlan,
  subscriptionPlanKey,
  currentSubscriptionInterval,
  intervalButton
) => {
  return currentSubscriptionInterval === intervalButton && selectedPlan === subscriptionPlanKey
    ? true
    : false;
};

const BillingCyclesNav = ({
  selectedPlan,
  subscriptionPlanKey,
  billingCycle,
  subscriptionInterval,
  availableIntervals,
}) => {
  const { setBillingCycle } = useSubscriptionsContext();
  const availableBillingCycles = getAvailableBillingCycles(availableIntervals);

  return (
    <>
      {Object.keys(availableBillingCycles).map(interval => {
        return (
          <BillingCycleButton
            key={getIntervalCycle(BILLING_CYCLES, interval)}
            onClick={() => setBillingCycle(getIntervalCycle(BILLING_CYCLES, interval))}
            className={cx({
              [activeBillingCycleButton]:
                getIntervalCycle(BILLING_CYCLES, interval) === billingCycle,
              [disabledBillingCycleButton]: shouldButtonBeDisabled(
                selectedPlan,
                subscriptionPlanKey,
                subscriptionInterval,
                BILLING_CYCLES[interval].cycle
              ),
            })}
            disabled={shouldButtonBeDisabled(
              selectedPlan,
              subscriptionPlanKey,
              subscriptionInterval,
              BILLING_CYCLES[interval].cycle
            )}
          >
            {getIntervalLabel(interval)}
          </BillingCycleButton>
        );
      })}
    </>
  );
};

const PriceLabel = props => {
  const { selectedPlan, priceDetails, interval } = props;
  if (SUBSCRIPTIONS.creator.plan_codes.includes(selectedPlan)) {
    return <div>{parse(getPricingPlanIntervalSubtitle(priceDetails, 'monthly'))}</div>;
  }
  const paragraphNumber = (
    getPricingPlanIntervalSubtitle(priceDetails, interval).match(/<p>/g) || []
  ).length;
  return (
    <div
      className={cx({
        [multiParagraphSubtitleMobile]: paragraphNumber > 1,
      })}
    >
      {parse(getPricingPlanIntervalSubtitle(priceDetails, interval))}
    </div>
  );
};

export const SubscriptionModal = ({
  disableAction = false,
  busy,
  selectedPlan,
  selectPlan,
  onClose,
  actionText,
  action,
  changingSubscription,
  subscriptionInterval,
  subscriptionPlanKey,
  subscriptionModalType,
}: Props) => {
  const subscriptionsPage = useSubscriptionsPage();
  const pricingPlans = usePricingPlans();
  const planCopy = usePricingPlan(selectedPlan);
  const pricingPlanPricingDetails = getPricingPlanPricingDetails(planCopy);
  const { billingCycle, setBillingCycle, selectedPlanNumber } = useSubscriptionsContext();
  const [visiblePlans, setVisiblePlans] = useState([]);
  const locale = useLocale();

  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const handlePlanIntervalChoices = () => {
    const currentPlan = subscriptionPlanKey;
    let currentInterval = '';
    if (changingSubscription) {
      currentInterval = getSeatInterval(changingSubscription);
    }
    const newPlan = selectedPlan;

    if (currentPlan !== newPlan) {
      if (currentInterval === defaultInterval) {
        setBillingCycle(getNextAvailableInterval(BILLING_CYCLES, defaultInterval));
      }
    }
  };

  useEffect(() => {
    if (!billingCycle) {
      setBillingCycle(getIntervalCycle(BILLING_CYCLES, defaultInterval));
    }
    selectPlan(selectedPlan);
  }, []);

  const filteredPricingPlans = (): Array<any> => {
    const plans = [SUBSCRIPTIONS.enterpriseBasic.key];

    if (changingSubscription) {
      const filteredPlans = plans.filter(plan => {
        return (
          !SUBSCRIPTIONS.creator.plan_codes.includes(plan) &&
          !SUBSCRIPTIONS.enterprise.plan_codes.includes(plan)
        );
      });
      return filteredPlans;
    }
    return plans;
  };

  const getModalTitle = (
    subscriptionModalType: string,
    selectedPlanNumber: string | number
  ): string => {
    let title = '';
    if (subscriptionModalType === ACTIONS.add) {
      title = getAddSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.change) {
      title = getChangeSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.downgrade) {
      title = getDowngradeSubscriptionModalTitle(subscriptionsPage);
    } else if (subscriptionModalType === ACTIONS.upgrade) {
      title = getUpgradeSubscriptionModalTitle(subscriptionsPage);
    }

    const planNumber = selectedPlanNumber ? selectedPlanNumber : '';

    return title.replace('$NUMBER$', planNumber);
  };

  const promotionalText = ((): string => {
    if (changingSubscription) {
      const isIntervalChange = getSubscriptionPlanKey(changingSubscription) === selectedPlan;
      if (isIntervalChange) {
        return getSubscriptionsPagePromotionalLabelIntervalChange(subscriptionsPage);
      }

      return getSubscriptionsPagePromotionalLabelUpgradePlan(subscriptionsPage);
    }
    return getSubscriptionsPagePromotionalLabelNewPlan(subscriptionsPage);
  })();

  const promotionAvailable = isPromotionAvailable(subscriptionsPage, selectedPlan, billingCycle);
  const downgradingBusiness = changingSubscription
    ? isBusinessSubscription(getSubscriptionPlanKey(changingSubscription)) &&
      isCreatorProSubscription(selectedPlan)
    : false;
  const downgradingInterval = changingSubscription
    ? INTERVAL_MONTHLY_DURATION[billingCycle] <
        INTERVAL_MONTHLY_DURATION[getSeatInterval(changingSubscription)] &&
      selectedPlan === getSubscriptionPlanKey(changingSubscription)
    : false;

  useEffect(() => {
    const filteredPlans = filteredPricingPlans();
    setVisiblePlans(filteredPlans);
  }, []);

  const handleClose = () => {
    if (busy) return;
    onClose();
  };

  const getPlanInclusionList = planCode => {
    if (isCreatorProSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.loyalMember.prevInclusion', '')}</h3>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMember.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isBusinessSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.loyalMemberPlus.prevInclusion', '')}</h3>
          <ul>
            {t('pricing.plans.plan.inclusions.loyalMemberPlus.list', {
              returnObjects: true,
              defaultValue: [''],
            }).map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      );
    }

    if (isEnterpriseSubscription(planCode)) {
      return (
        <>
          <h3>{t('pricing.plans.plan.inclusions.enterprise.prevInclusion', '')}</h3>
        </>
      );
    }

    return '';
  };

  return (
    <ClassicModalLayout
      bannerClassName={cx({
        [enterprisePlanCss]: isEnterpriseBasicSubscription(selectedPlan),
      })}
      className={landscapeSubscriptionModal}
      onClose={handleClose}
      label={getModalTitle(subscriptionModalType, selectedPlanNumber)}
      fullSized={true}
    >
      <ClassicModalContentLayout className={centerMobileModalHeader}>
        <Nav>
          <ul>
            {visiblePlans.map(planKey => {
              const plan = getPricingPlan(pricingPlans, planKey);
              return (
                <li key={planKey}>
                  <NavButton
                    locale={locale}
                    selected={selectedPlan === planKey}
                    onClick={() => {
                      if (busy) return;
                      if (planKey === selectedPlan) return;
                      setBillingCycle(defaultInterval);
                      selectPlan(planKey);
                      handlePlanIntervalChoices();
                    }}
                  >
                    {getPricingPlanName(plan)}
                  </NavButton>
                </li>
              );
            })}
          </ul>
        </Nav>
        <MobilePricingContainer>
          <MobilePricing>
            <h3>{getPricingPlanIntervalPrice(pricingPlanPricingDetails, billingCycle)}</h3>
            {!isCreatorSubscription(selectedPlan) && (
              <sub>{getPricingPlanMonthlyBillingSubtext(planCopy)}</sub>
            )}
            <PriceLabel
              selectedPlan={selectedPlan}
              priceDetails={pricingPlanPricingDetails}
              interval={billingCycle}
            />
          </MobilePricing>
          <>
            {promotionAvailable && !downgradingBusiness && !downgradingInterval && (
              <PromotionLabel
                className={cx({
                  [enterprisePlanCss]: isEnterpriseBasicSubscription(selectedPlan),
                })}
              >
                <div>{parse(promotionalText)}</div>
              </PromotionLabel>
            )}
          </>
          <BillingCyclesWrapper
            className={cx({ [hiddenBillingCycles]: isEnterpriseSubscription(selectedPlan) })}
          >
            <BillingCyclesNav
              selectedPlan={selectedPlan}
              subscriptionPlanKey={subscriptionPlanKey}
              billingCycle={billingCycle}
              subscriptionInterval={subscriptionInterval}
              availableIntervals={getPricingPlanAvailableIntervals(planCopy)}
            />
          </BillingCyclesWrapper>
        </MobilePricingContainer>
        <BodyContent>
          <InfoSection>
            <InfoDescription>
              <InclusionsList>{getPlanInclusionList(selectedPlan)}</InclusionsList>
              <ExclusionsList>{parse(getPricingPlanExclusions(planCopy))}</ExclusionsList>
            </InfoDescription>
            <ActionButtonWrapper>
              <Button
                className={cx(
                  {
                    [hiddenButtonClass]: disableAction,
                  },
                  addButtonCss
                )}
                loading={busy}
                onClick={action}
                disabled={disableAction}
              >
                {actionText}
              </Button>
            </ActionButtonWrapper>
          </InfoSection>
          <PricingSection>
            <Price currencyCode={CURRENCY_CODES[locale]}>
              <h3>
                {getPricingPlanIntervalPrice(pricingPlanPricingDetails, billingCycle)}
                {!isCreatorSubscription(selectedPlan) && (
                  <sub>{getPricingPlanMonthlyBillingSubtext(planCopy)}</sub>
                )}
              </h3>
              <PriceLabel
                selectedPlan={selectedPlan}
                priceDetails={pricingPlanPricingDetails}
                interval={billingCycle}
              />
            </Price>
            <BillingCyclesWrapper
              className={cx({ [hiddenBillingCycles]: isEnterpriseSubscription(selectedPlan) })}
            >
              <BillingCyclesNav
                selectedPlan={selectedPlan}
                subscriptionPlanKey={subscriptionPlanKey}
                billingCycle={billingCycle}
                subscriptionInterval={subscriptionInterval}
                availableIntervals={getPricingPlanAvailableIntervals(planCopy)}
              />
            </BillingCyclesWrapper>
            <>
              {promotionAvailable && !downgradingBusiness && !downgradingInterval && (
                <PromotionLabel
                  className={cx({
                    [japaneseLocalePromotionLabel]: locale === 'ja',
                    [enterprisePlanCss]: isEnterpriseBasicSubscription(selectedPlan),
                  })}
                >
                  <div>{parse(promotionalText)}</div>
                </PromotionLabel>
              )}
            </>
          </PricingSection>
        </BodyContent>
      </ClassicModalContentLayout>
    </ClassicModalLayout>
  );
};

const AddEnterpriseBasicSubscriptionModal = ({ onClose, ...props }: Props) => {
  const { selectedPlan } = props;
  const { billingCycle, setBillingCycle, addNewSubscription } = useSubscriptionsContext();
  const [busy, setBusy] = useState(false);
  const miscCopy = useMiscCopy();
  const subscriptionsPage = useSubscriptionsPage();
  const promotionalDefaultInterval = getSubscriptionsPagePromotionalDefaultInterval(
    subscriptionsPage
  );
  const defaultInterval = promotionalDefaultInterval || 'annual';

  const actionText = getMiscCopyAdd(miscCopy);

  const handleClose = () => {
    if (busy) return;
    setBillingCycle(defaultInterval);
    onClose();
  };

  const handleAddSubscription = () => {
    if (busy) return;
    setBusy(true);
    addNewSubscription(selectedPlan, billingCycle)
      .then(() => {
        setBillingCycle(defaultInterval);
        onClose();
      })
      .catch(error => {
        // $FlowFixMe: removes type checking for Sentry as provisional solution
        Sentry.captureMessage('Something went wrong when adding subscription');
        Sentry.captureException(error);
        console.error(error);
        setBusy(false);
      });
  };

  const action = () => {
    handleAddSubscription();
  };

  return (
    <SubscriptionModal
      {...props}
      onClose={handleClose}
      busy={busy}
      action={action}
      actionText={actionText}
      changingSubscription={false}
      subscriptionModalType={ACTIONS.add}
    />
  );
};

export default AddEnterpriseBasicSubscriptionModal;
